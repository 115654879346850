<template>
  <div class="page-box" style="height:100%;">
    <div class="h-title" v-if="title">{{title}}</div>
    <div :class="['h-result', status]" v-if="status==='success' || status === 'failed'">
      <span class="label">状态：</span>
      {{mapStatus(status)}}
      <span v-if="failedDesc && status==='failed'">({{failedDesc}})</span>
      <span class="btn" v-if="src" @click="download(src, title)">下载</span>
    </div>
    <div class="h-result" v-if="status==='upload'">
      <span class="label requrie">应助确认：</span>
      <span class="btn" @click="handleSuccess">成功</span>
      <span class="btn" @click="handleFailed">失败</span>
      <span style="margin-left:10px;color:red;">成功确认后下载</span>
    </div>
    <div :style="{height: pageHeight + 'px'}">
      <iframe :src="src" width="100%" height="100%" type="application/pdf"></iframe>
    </div>
    <help-failed-win :show="showFailWin" @on-hide="showFailWin=false" @on-ok="onFailed"></help-failed-win>
    <login-tip-win2 :show="showLoginTipWin" @on-hide="showLoginTipWin=false" text="微信扫码登陆后可操作"></login-tip-win2>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :tipType="tipType" :duration="duration" :text="tipText"></tooltip-win>
  </div>
</template>
<script>
import HelpFailedWin from './help-failed-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import TooltipWin from '../login-tip-win/tooltip-win'
import Login from '../../utils/login'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {HelpFailedWin, LoginTipWin2, TooltipWin},
  mixins: [Login],
  data () {
    return {
      src: '',
      showFailWin: false,
      showLoginTipWin: false,
      authUnionid: '',

      id: '',
      status: '',  // new, helping, upload, success, failed, 求助中，应助中，已上传，应助成功，应助失败（求助者可以确认成功，确认失败，和取消，重新求助，）
      newTime: '',
      helpingTime: '',
      uploadTime: '',
      successTime: '',
      failedTime: '',
      title: '',
      pmid: '',
      doi: '',
      failedDesc: '',
      fileId: '',
      unionid: '',
      helpUid: '',
      helpNickname: '',
      helpHeadimgurl: '',

      showTipWin: false,
      tipType: '',
      duration: '',
      tipText: ''
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight - 36 - 40 - (this.title ? 30 : 0) - (window.$upgrade ? 22 : 0)
    },
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.params && to.params.id) {
      document.title = to.params.id
      next(vm => {
        vm.src = vm.httpRoot + '/fulltext/' + to.params.id
        vm.id = to.query.id || ''
        if (vm.id) {
          vm.loadPF()
        }
      })
    } else {
      document.title = '文献不存在'
      next()
    }
  },
  methods: {
    mapStatus (status) {
      return status === 'new'
              ? '求助'
              : status === 'helping'
                ? '应助中'
                : status === 'upload'
                  ? '已上传'
                  : status === 'success'
                    ? '应助成功'
                    : status === 'failed'
                      ? '应助失败'
                      : ''
    },
    loginValid (e) {
      if (!this.loggedIn) {
        e.preventDefault()
        updateStorageItem('route', {name: 'helppdf', params: {id: this.pmid}, query: {id: this.id}})
        this.showLoginTipWin = true
        return false
      } else {
        return true
      }
    },
    loadPF () {
      this.$http.get(`${this.httpRoot}/paper/fulltext/detail`, authenticate({params: {id: this.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.fulltext) {
          this.status = res.fulltext.status || ''
          this.title = res.fulltext.title || ''
          this.pmid = res.fulltext.pmid || ''
          this.doi = res.fulltext.doi || ''
          this.fileId = res.fulltext.fileId || ''
          this.url = res.fulltext.url || ''
          this.unionid = res.fulltext.unionid || ''
          this.helpUid = res.fulltext.helpUid || ''
          this.failedDesc = res.fulltext.failedDesc || ''
          this.newTime = res.fulltext.newTime || ''
          this.helpingTime = res.fulltext.helpingTime || ''
          this.uploadTime = res.fulltext.uploadTime || ''
          this.successTime = res.fulltext.successTime || ''
          this.failedTime = res.fulltext.failedTime || ''
          this.helpNickname = res.fulltext.helpNickname || ''
          this.helpHeadimgurl = res.fulltext.helpHeadimgurl || ''
        }
      })
      .catch(err => {
        if (err.status === 401) {
          // 解决没有登录且没有临时token的情况
          this.authUnionid = 'no login'
        } else {
          catchExpired(err, this)
        }
      })
    },
    handleSuccess (e) {
      if (!this.loginValid(e)) return
      this.helpSuccess()
    },
    handleFailed (e) {
      if (!this.loginValid(e)) return
      this.showFailWin = true
    },
    onFailed (desc) {
      this.helpFailed(desc)
    },
    helpSuccess () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/success`, {id: this.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.status = 'success'
          this.successTip()
        } else {
          this.faildTip(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    helpFailed (desc) {
      this.$http.post(`${this.httpRoot}/paper/fulltext/failed`, {id: this.id, failedDesc: desc}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.status = 'failed'
          this.failedDesc = desc
          this.successTip()
        } else {
          this.faildTip(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    successTip () {
      this.tipType = 'success'
      this.duration = 2000
      this.tipText = '确认成功'
      this.showTipWin = true
    },
    faildTip (text) {
      this.tipType = 'warning'
      this.duration = 2000
      this.tipText = text
      this.showTipWin = true
    },
    needFailedDesc () {
      this.tipType = 'warning'
      this.duration = 1500
      this.tipText = '填写失败原因'
      this.showTipWin = true
    },
    download (href, title) {
      this.$http.get(`${href}`, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = title + '.pdf'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
      // const a = document.createElement('a')
      // a.setAttribute('href', href)
      // a.setAttribute('download', title)
      // a.click()
    }
  }
}
</script>
<style lang="less">
.h-title {
  line-height: 28px;
  font-size: 14px;
  color: #3f5d94;
}
.h-result {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  .btn {
    padding: 2px 10px;
    margin: 0 5px;
    background-color: #7ba0e4;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #ffe8e8;
    }
  }
  &.success {
    color: #2e8540;
  }
  &.failed {
    color: #981a1a;
  }
}
</style>
